export default class DownloadUtils {
  static downloadBlob = (blob: Blob, filename: string): void => {
    const el = window.document.createElement('a')
    el.href = window.URL.createObjectURL(blob)
    el.download = filename
    document.body.appendChild(el)
    el.click()
    document.body.removeChild(el)
  }
}
