import api from 'api'
import { Dispatch, SetStateAction } from 'react'
import { Campaign } from 'types/campaign'

export default class CampaignFormService {
  static getCampaignImpressionsBasedOnAudience = ({
    campaignId,
    setEstimatedImpressions,
    setLoading,
  }: {
    campaignId: Campaign['id']
    setEstimatedImpressions: Dispatch<SetStateAction<number | null>>
    setLoading: Dispatch<SetStateAction<boolean>>
  }): void => {
    setLoading(true)

    api.campaign
      .getCampaignImpressionsBasedOnAudience(campaignId)
      .then(
        res => void setEstimatedImpressions(res.data.campaignImpressionsBasedOnAudience.impressions)
      )
      .catch(() => void {})
      .finally(() => void setLoading(false))
  }
}
