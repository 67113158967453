import Button, { ButtonSize } from 'components/Form/Button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Summary from '../Summary'
import { EstimatedAudienceEmissionsProps } from './types'
import service from './service'
import './EstimatedAudienceEmissions.scss'

const EstimatedAudienceEmissions: React.FC<EstimatedAudienceEmissionsProps> = ({ campaignId }) => {
  const { t } = useTranslation()
  const [estimatedImpressions, setEstimatedImpressions] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleClick = () => {
    void service.getCampaignImpressionsBasedOnAudience({
      campaignId,
      setEstimatedImpressions,
      setLoading,
    })
  }

  return (
    <Summary
      title={t(`form.campaign.summary.audience`)}
      quantity={estimatedImpressions}
      isLoading={loading}
    >
      {campaignId && (
        <Button
          className='EstimatedAudienceEmissions__button'
          size={ButtonSize.SMALL}
          onClick={handleClick}
        >
          {t('form.campaign.summary.recalculate')}
        </Button>
      )}
    </Summary>
  )
}

export default EstimatedAudienceEmissions
