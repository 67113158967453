import { client } from 'components/ApolloProvider'
import { loader } from 'graphql.macro'
import {
  Approval,
  Campaign,
  CampaignTarget,
  MediumUsage,
  OrganizationAttributes,
} from 'types/campaign'
import { MutationStateResponse, PaginationProps } from 'types/various'
import VariableUtils from 'utils/variable'
import { Agency } from 'types/agency'
import * as CAT from './types' // Campaign API types
import {
  CampaignLocationsFilePublicResponse,
  CampaignLocationsFileResponse,
  CampaignPdfAudienceData,
  GetCampaignImpressionsVariables,
  UpdateMediaCriteriaSearchAdminVariables,
  UpdateMediaCriteriaSearchEmployeeVariables,
  UpdateMediaCriteriaSearchSupportVariables,
  UpdateMediaCriteriaSearchVariables,
} from './types'
import {
  GET_CAMPAIGN_CHART_FILTERS,
  GET_CAMPAIGN_CHART_FILTERS_PUBLIC,
} from './getCampaignChartFilters'
import { GET_CAMPAIGN_DETAILS, GET_CAMPAIGN_DETAILS_PUBLIC } from './getCampaignDetails'
import { GET_CAMPAIGN_FOR_ACCEPTANCE_FIELDS } from './getCampaignForAcceptanceFields'
import { GET_CAMPAIGN_FOR_BASIC_FORM } from './getCampaignForBasicForm'
import { GET_CAMPAIGN_FOR_CREATIONS_FORM } from './getCampaignForCreationsForm'
import { GET_CAMPAIGN_FOR_DETAILS, GET_CAMPAIGN_FOR_DETAILS_PUBLIC } from './getCampaignForDetails'
import { GET_CAMPAIGN_FOR_DETAILS_FORM } from './getCampaignForDetailsForm'
import { GET_CAMPAIGN_IMPRESSIONS, GET_CAMPAIGN_IMPRESSIONS_PUBLIC } from './getCampaignImpressions'
import { GET_CAMPAIGNS_FOR_TABLE } from './getCampaignsForTable'
import { PAUSE_CAMPAIGN } from './pauseCampaign'
import { STOP_CAMPAIGN } from './stopCampaign'
import {
  GET_CAMPAIGN_FOR_EMISSION_SCHEDULE,
  GET_CAMPAIGN_FOR_EMISSION_SCHEDULE_PUBLIC,
} from './getCampaignForEmissionSchedule'
import { CREATE_SKETCH_CAMPAIGN } from './createSketchCampaign'
import { UPDATE_CAMPAIGN } from './updateCampaign'
import {
  UPDATE_MEDIA_CRITERIA_SEARCH,
  UPDATE_MEDIA_CRITERIA_SEARCH_ADMIN,
  UPDATE_MEDIA_CRITERIA_SEARCH_EMPLOYEE,
  UPDATE_MEDIA_CRITERIA_SEARCH_SUPPORT,
} from './updateMediaCriteriaSearch'
import { PUBLISH_CAMPAIGN } from './publishCampaign'
import { GET_CAMPAIGN_ESTIMATED_IMPRESSIONS } from './campaignEstimatedImpressions'
import { GET_CAMPAIGN_FILTERS } from './getCampaignFilters'
import { Filters } from 'pages/Campaign/CampaignTable/CampaignTableFilters'
import { CampaignReservation } from 'pages/Campaign/CampaignForm/Reservation'
import { RESERVE_CAMPAIGN } from './reserveCampaign'
import { UPDATE_CAMPAIGN_RESERVATION } from './updateCampaignReservation'
import { CONFIRM_CAMPAIGN_RESERVATION } from './confirmCampaignReservation'
import { CANCEL_CAMPAIGN_RESERVATION } from './cancelCampaignReservation'
import { User } from 'types/user'
import { GET_CAMPAIGN_MEDIA_USAGE } from './getMediaUsage'
import { UPDATE_CAMPAIGN_MEDIA_USAGE } from './updateMediaUsage'
import { UPDATE_CAMPAIGN_ORGANIZATION_ATTRIBUTES } from './updateCampaignOrganizationAttributes'
import { FetchResult } from '@apollo/client'
import { GET_CAMPAIGN_LOCATION_FILE } from './getCampaignLocationsFile'
import { GET_CAMPAIGN_LOCATION_FILE_PUBLIC } from './getCampaignLocationsFilePublic'
import { UPDATE_CAMPAIGN_WITHOUT_SUPERVISORS } from './updateCampaignWithoutSupervisors'
import { SEND_CAMPAIGN_TO_ACCEPTANCE } from './sendCampaignToAcceptance'
import { Company } from '../companies/types'
import { GET_CAMPAIGN_IMPRESSIONS_BASED_ON_AUDIENCE } from './campaignImpressionsBasedOnAudience'
import { GET_CAMPAIGN_AUDIENCE_DATA } from './getCampaignAudienceData'

const campaignApi = {
  getCampaignForDetails: (id: Campaign['id']): Promise<CAT.GetCampaignForDetailsAQR> =>
    client.query({
      query: GET_CAMPAIGN_FOR_DETAILS,
      variables: { id },
    }),

  getCampaignForDetailsPublic: (
    uuid: Campaign['uuid']
  ): Promise<CAT.GetCampaignForDetailsPublicAQR> =>
    client.query({
      query: GET_CAMPAIGN_FOR_DETAILS_PUBLIC,
      variables: { campaignUuid: uuid },
    }),

  getCampaignForAcceptanceFields: (
    id: Campaign['id']
  ): Promise<CAT.GetCampaignForAcceptanceFieldsAQR> =>
    client.query({
      query: GET_CAMPAIGN_FOR_ACCEPTANCE_FIELDS,
      variables: { id },
    }),

  getCampaignForBasicForm: (id: Campaign['id']): Promise<CAT.GetCampaignForBasicFormAQR> =>
    client.query({
      query: GET_CAMPAIGN_FOR_BASIC_FORM,
      variables: { id },
    }),

  getCampaignForDetailsForm: (id: Campaign['id']): Promise<CAT.GetCampaignForDetailsFormAQR> =>
    client.query({
      query: GET_CAMPAIGN_FOR_DETAILS_FORM,
      variables: { id },
    }),

  getCampaignForCreationsForm: (
    id: Campaign['id']
  ): Promise<CAT.GetCampaignForCreationsFormAQR> => {
    return client.query({
      query: GET_CAMPAIGN_FOR_CREATIONS_FORM,
      variables: { id },
    })
  },

  getCampaignFilters: (): Promise<CAT.GetCampaignFiltersAQR> =>
    client.query({
      query: GET_CAMPAIGN_FILTERS,
    }),

  getCampaignsForTable: (
    variables: PaginationProps & { filters: Filters }
  ): Promise<CAT.GetCampaignsForTableAQR> => {
    const { startDateFrom, startDateTo, endDateFrom, endDateTo, text, ...filters } =
      variables.filters

    return client.query({
      query: GET_CAMPAIGNS_FOR_TABLE,
      variables: {
        ...variables,
        filters: {
          ...filters,
          startDateFrom: startDateFrom || null,
          startDateTo: startDateTo || null,
          endDateFrom: endDateFrom || null,
          endDateTo: endDateTo || null,
          text: text || null,
        },
      },
    })
  },

  getCampaignForEmissionSchedule: (
    id: Campaign['id']
  ): Promise<CAT.GetCampaignForEmissionScheduleAQR> =>
    client.query({
      query: GET_CAMPAIGN_FOR_EMISSION_SCHEDULE,
      variables: { id },
    }),

  getCampaignForEmissionSchedulePublic: (
    uuid: Campaign['uuid']
  ): Promise<CAT.GetCampaignForEmissionSchedulePublicAQR> =>
    client.query({
      query: GET_CAMPAIGN_FOR_EMISSION_SCHEDULE_PUBLIC,
      variables: { campaignUuid: uuid },
    }),

  getCampaignDetails: (id: Campaign['id']): Promise<CAT.GetCampaignDetailsAQR> =>
    client.query({
      query: GET_CAMPAIGN_DETAILS,
      variables: { id },
    }),

  getCampaignDetailsPublic: (uuid: Campaign['uuid']): Promise<CAT.GetCampaignDetailsPublicAQR> =>
    client.query({
      query: GET_CAMPAIGN_DETAILS_PUBLIC,
      variables: { campaignUuid: uuid },
    }),

  createSketchCampaign: (v: {
    agencyId: Agency['id'] | null
    name: Campaign['name']
    briefName: Campaign['briefName']
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<CAT.CreateSketchCampaignFR> =>
    client.mutate({
      mutation: CREATE_SKETCH_CAMPAIGN,
      variables: {
        agencyId: v.agencyId || null,
        name: v.name,
        briefName: v.briefName,
        startDate: v.startDate || null,
        endDate: v.endDate || null,
      },
    }),

  updateCampaign: (v: {
    id: Campaign['id']
    agencyId: Agency['id'] | null
    advertiserId: Company['id'] | null
    name: Campaign['name'] | null
    briefName: Campaign['briefName'] | null
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
    supervisors: User['id'][]
  }): Promise<CAT.UpdateCampaignFR> =>
    client.mutate({
      mutation: UPDATE_CAMPAIGN,
      variables: {
        id: v.id,
        agencyId: v.agencyId || null,
        advertiserId: v.advertiserId || null,
        name: v.name || null,
        briefName: v.briefName || null,
        startDate: v.startDate || null,
        endDate: v.endDate || null,
        supervisors: v.supervisors,
      },
    }),

  updateCampaignWithoutSupervisors: (v: {
    id: Campaign['id']
    agencyId: Agency['id'] | null
    advertiserId: Company['id'] | null
    name: Campaign['name'] | null
    briefName: Campaign['briefName'] | null
    startDate: Campaign['startDate'] | null
    endDate: Campaign['endDate'] | null
  }): Promise<CAT.UpdateCampaignFR> =>
    client.mutate({
      mutation: UPDATE_CAMPAIGN_WITHOUT_SUPERVISORS,
      variables: {
        id: v.id,
        agencyId: v.agencyId || null,
        advertiserId: v.advertiserId || null,
        name: v.name || null,
        briefName: v.briefName || null,
        startDate: v.startDate || null,
        endDate: v.endDate || null,
      },
    }),

  updateMediaCriteriaSearch: (
    variables: UpdateMediaCriteriaSearchVariables
  ): Promise<CAT.UpdateMediaCriteriaSearchFR> => {
    const { target, budget: rawBudget, emissions: rawImpressions } = variables
    const budget =
      rawBudget && target === CampaignTarget.BUDGET
        ? VariableUtils.numberToGqlFloat(rawBudget)
        : null
    const emissions =
      rawImpressions && target === CampaignTarget.IMPRESSIONS
        ? VariableUtils.numberToGqlInt(rawImpressions)
        : null

    return client.mutate({
      mutation: UPDATE_MEDIA_CRITERIA_SEARCH,
      variables: {
        ...variables,
        budget,
        impressions: emissions,
        priority: VariableUtils.numberToGqlInt(variables.priority),
      },
    })
  },

  updateMediaCriteriaSearchEmployee: (
    variables: UpdateMediaCriteriaSearchEmployeeVariables
  ): Promise<CAT.UpdateMediaCriteriaSearchFR> => {
    const { target, budget: rawBudget, emissions: rawImpressions } = variables
    const budget =
      rawBudget && target === CampaignTarget.BUDGET
        ? VariableUtils.numberToGqlFloat(rawBudget)
        : null
    const emissions =
      rawImpressions && target === CampaignTarget.IMPRESSIONS
        ? VariableUtils.numberToGqlInt(rawImpressions)
        : null

    return client.mutate({
      mutation: UPDATE_MEDIA_CRITERIA_SEARCH_EMPLOYEE,
      variables: {
        ...variables,
        budget,
        impressions: emissions,
        priority: VariableUtils.numberToGqlInt(variables.priority),
      },
    })
  },

  updateMediaCriteriaSearchAdmin: (
    variables: UpdateMediaCriteriaSearchAdminVariables
  ): Promise<CAT.UpdateMediaCriteriaSearchFR> => {
    const {
      target,
      budget: rawBudget,
      emissions: rawImpressions,
      audience: rawAudience,
    } = variables
    const budget =
      rawBudget && target === CampaignTarget.BUDGET
        ? VariableUtils.numberToGqlFloat(rawBudget)
        : null
    const emissions =
      rawImpressions && target === CampaignTarget.IMPRESSIONS
        ? VariableUtils.numberToGqlInt(rawImpressions)
        : null
    const audience =
      rawAudience && target === CampaignTarget.AUDIENCE
        ? VariableUtils.numberToGqlInt(rawAudience)
        : null

    return client.mutate({
      mutation: UPDATE_MEDIA_CRITERIA_SEARCH_ADMIN,
      variables: {
        ...variables,
        budget,
        hiddenImpressions: VariableUtils.numberToGqlInt(variables.hiddenImpressions || 0),
        impressions: emissions,
        priority: VariableUtils.numberToGqlInt(variables.priority),
        audience: audience,
        hiddenAudience: VariableUtils.numberToGqlInt(variables.hiddenAudience || 0),
      },
    })
  },

  updateMediaCriteriaSearchSupport: (
    variables: UpdateMediaCriteriaSearchSupportVariables
  ): Promise<CAT.UpdateMediaCriteriaSearchFR> => {
    const { target, budget: rawBudget, emissions: rawImpressions } = variables
    const budget =
      rawBudget && target === CampaignTarget.BUDGET
        ? VariableUtils.numberToGqlFloat(rawBudget)
        : null
    const emissions =
      rawImpressions && target === CampaignTarget.IMPRESSIONS
        ? VariableUtils.numberToGqlInt(rawImpressions)
        : null

    return client.mutate({
      mutation: UPDATE_MEDIA_CRITERIA_SEARCH_SUPPORT,
      variables: {
        ...variables,
        budget,
        impressions: emissions,
        priority: VariableUtils.numberToGqlInt(variables.priority),
      },
    })
  },

  updateApproval: (variables: {
    approvalId: Approval['id']
    approved: Approval['approved']
  }): Promise<FetchResult<{ updateApproval: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./updateApproval.gql'),
      variables,
    }),

  getCampaignImpressions: (
    variables: GetCampaignImpressionsVariables
  ): Promise<CAT.GetCampaignImpressionsAQR> =>
    client.query({
      query: GET_CAMPAIGN_IMPRESSIONS,
      variables,
    }),

  getCampaignImpressionsPublic: (
    variables: GetCampaignImpressionsVariables
  ): Promise<CAT.GetCampaignImpressionsPublicAQR> =>
    client.query({
      query: GET_CAMPAIGN_IMPRESSIONS_PUBLIC,
      variables,
    }),

  acceptCampaign: (variables: { id: Campaign['id'] }): Promise<CAT.acceptCampaignFR> =>
    client.mutate({
      mutation: loader('./acceptCampaign.gql'),
      variables,
    }),

  rejectCampaign: (variables: { id: Campaign['id'] }): Promise<CAT.rejectCampaignFR> =>
    client.mutate({
      mutation: loader('./rejectCampaign.gql'),
      variables,
    }),

  copyCampaign: (variables: {
    id: Campaign['id']
  }): Promise<
    FetchResult<{ copyCampaign: MutationStateResponse & { campaign: { id: string } } }>
  > =>
    client.mutate({
      mutation: loader('./copyCampaign.graphql'),
      variables,
    }),

  deleteCampaign: (
    id: Campaign['id']
  ): Promise<FetchResult<{ destroyCampaign: MutationStateResponse }>> =>
    client.mutate({
      mutation: loader('./destroyCampaign.gql'),
      variables: { id },
    }),

  getCampaignChartFilters: (id: Campaign['id']): Promise<CAT.GetCampaignChartFiltersAQR> =>
    client.query({
      query: GET_CAMPAIGN_CHART_FILTERS,
      variables: { id },
    }),

  getCampaignChartFiltersPublic: (
    uuid: Campaign['uuid']
  ): Promise<CAT.GetCampaignChartFiltersPublicAQR> =>
    client.query({
      query: GET_CAMPAIGN_CHART_FILTERS_PUBLIC,
      variables: { campaignUuid: uuid },
    }),

  pauseCampaign: (id: Campaign['id']): Promise<CAT.PauseCampaignFR> =>
    client.mutate({
      mutation: PAUSE_CAMPAIGN,
      variables: { id },
    }),

  stopCampaign: (id: Campaign['id']): Promise<CAT.StopCampaignFR> =>
    client.mutate({
      mutation: STOP_CAMPAIGN,
      variables: { id },
    }),

  publishCampaign: (variables: { campaignId: Campaign['id'] }): Promise<CAT.PublishCampaignFR> =>
    client.mutate({
      mutation: PUBLISH_CAMPAIGN,
      variables,
    }),

  getCampaignEstimatedImpressions: (
    campaignId: Campaign['id'],
    experimental: boolean
  ): Promise<CAT.GetCampaignEstimatedImpressionsQR> =>
    client.query({
      query: GET_CAMPAIGN_ESTIMATED_IMPRESSIONS,
      variables: { campaignId, experimental },
    }),

  getCampaignImpressionsBasedOnAudience: (
    campaignId: Campaign['id']
  ): Promise<CAT.GetCampaignImpressionsBasedOnAudience> =>
    client.query({
      query: GET_CAMPAIGN_IMPRESSIONS_BASED_ON_AUDIENCE,
      variables: { campaignId },
    }),

  reserveCampaign: (variables: CampaignReservation): Promise<CAT.ReserveCampaignFR> =>
    client.mutate({
      mutation: RESERVE_CAMPAIGN,
      variables,
    }),

  updateCampaignReservation: (
    variables: CampaignReservation
  ): Promise<CAT.UpdateCampaignReservationFR> =>
    client.mutate({
      mutation: UPDATE_CAMPAIGN_RESERVATION,
      variables,
    }),

  confirmCampaignReservation: (
    variables: Pick<CampaignReservation, 'id'>
  ): Promise<CAT.ConfirmCampaignReservationFR> =>
    client.mutate({
      mutation: CONFIRM_CAMPAIGN_RESERVATION,
      variables,
    }),

  cancelCampaignReservation: (
    variables: Pick<CampaignReservation, 'id'>
  ): Promise<CAT.CancelCampaignReservationFR> =>
    client.mutate({
      mutation: CANCEL_CAMPAIGN_RESERVATION,
      variables,
    }),

  sendCampaignToAcceptance: (
    variables: Pick<CampaignReservation, 'id'>
  ): Promise<CAT.SendCampaignToAcceptanceFR> =>
    client.mutate({
      mutation: SEND_CAMPAIGN_TO_ACCEPTANCE,
      variables,
    }),

  getMediaUsage: (id: Campaign['id']): Promise<CAT.GetCampaignMediaAQR> =>
    client.query({
      query: GET_CAMPAIGN_MEDIA_USAGE,
      variables: { id },
    }),

  updateMediaUsage: (variables: {
    campaignId: Campaign['id']
    media: MediumUsage[]
  }): Promise<CAT.UpdateCampaignMediaUsageFR> => {
    return client.mutate({
      mutation: UPDATE_CAMPAIGN_MEDIA_USAGE,
      variables,
    })
  },

  updateCampaignOrganizationAttributes: (
    campaignId: Campaign['id'],
    organizationAttributes: OrganizationAttributes
  ): Promise<FetchResult<MutationStateResponse>> => {
    return client.mutate({
      mutation: UPDATE_CAMPAIGN_ORGANIZATION_ATTRIBUTES,
      variables: {
        campaignId,
        organizationAttribute: organizationAttributes,
      },
    })
  },

  fetchCampaignLocationsFile: (
    campaignId: Campaign['id']
  ): Promise<CampaignLocationsFileResponse> => {
    return client.query({
      query: GET_CAMPAIGN_LOCATION_FILE,
      variables: { campaignId },
    })
  },

  fetchCampaignPdfAudienceData: (
    campaignId: Campaign['id']
  ): Promise<FetchResult<{ campaignPdfAudienceData: CampaignPdfAudienceData }>> => {
    return client.query({
      query: GET_CAMPAIGN_AUDIENCE_DATA,
      variables: { campaignId },
    })
  },

  fetchCampaignLocationsFilePublic: (
    uuid: Campaign['uuid']
  ): Promise<CampaignLocationsFilePublicResponse> => {
    return client.query({
      query: GET_CAMPAIGN_LOCATION_FILE_PUBLIC,
      variables: { campaignUuid: uuid },
    })
  },
}

export default campaignApi
