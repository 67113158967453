import { CREATIONS_MAX_FILE_SIZE, CREATIONS_MAX_FILES } from 'constant'
import VariableUtils from 'utils/variable'

export default {
  common: {
    actions: 'Akcje',
    activateCampaign: 'Aktywuj kampanię',
    advertiser: 'Reklamodawca',
    rejectCampaign: 'Odrzuć kampanie',
    add: 'Dodaj',
    agency: 'Agencja',
    agglomeration: 'Aglomeracja',
    agreement: 'Umowa',
    alert: 'Alert',
    info: 'Info',
    alertsNumber: 'Liczba alertów',
    informationsNumber: 'Liczba informacji',
    alerts: ' Alerty',
    archive: 'Archiwizuj',
    archived: 'Zarchiwizowano',
    archivedInfo:
      'Zarchiwizowano, możliwy jest podgląd. Ponowne użycie wymaga dodania kreacji jeszcze raz.',
    asId: 'AS_ID',
    back: 'Wróć',
    briefName: 'Nazwa briefu',
    browse: 'przeglądaj',
    budget: 'Budżet',
    budgetIssued: 'Wydany budżet',
    building: 'Galeria',
    campaign: 'Kampania',
    campaignDetails: 'Szczegóły kampanii',
    campaignTerm: 'Termin kampanii',
    campaigns: 'Kampanie',
    cancel: 'Anuluj',
    checkInbox: 'Sprawdź skrzynkę',
    city: 'Miasto',
    comment: 'Komentarz',
    create: 'Utwórz',
    createdAt: 'Data stworzenia',
    scheduleDates: 'Daty emisji',
    createCampaign: 'Utwórz kampanię',
    createAgency: 'Utwórz agencję',
    createUser: 'Utwórz użytkownika',
    createRole: 'Utwórz role',
    creationList: 'Lista kreacji',
    agglomerationList: 'Zrealizowane kontakty - podział na aglomeracje',
    creationName: 'Nazwa kreacji',
    creationDetails: 'Szczegóły kreacji',
    archivedAt: 'Data archiwizacji',
    emissionStartDate: 'Data dodania',
    deactivate: 'Dezaktywuj',
    dismiss: 'Odrzuć',
    download: 'Pobierz',
    edit: 'Edytuj',
    createCopy: 'Utwórz kopie',
    email: 'E-mail',
    emissions: {
      a: 'emisji',
      b: 'Emisje',
      realized: 'Zrealizowane emisje',
    },
    audience: {
      a: 'kontaktów',
      b: 'Kontakty',
      realized: 'Zrealizowane kontakty',
    },
    impressions: {
      a: 'emisji',
      b: 'Emisje',
      realized: 'Zrealizowane emisje',
    },
    emissonSchedule: 'Plan emisji',
    empty: 'puste',
    enterEmail: 'Wpisz e-mail',
    enterFirstName: 'Wpisz imię',
    enterLastName: 'Wpisz nazwisko',
    enterPassword: 'Wpisz hasło',
    enterPasswordAgain: 'Wpisz ponownie hasło',
    fileName: 'Nazwa pliku',
    filters: 'Filtry',
    finished: 'Gotowe',
    firstName: 'Imię',
    format: 'Format',
    from: 'Od',
    indoor: 'Indoor',
    lastName: 'Nazwisko',
    loading: 'Wczytywanie...',
    locations: 'Lokalizacje',
    mediaList: 'Lista nośników',
    mediaQuantity: 'Liczba nośników',
    mediaDuration: 'Deklarowana długość kreacji',
    agglomerationOrCity: 'Aglomeracja lub miasto',
    mediaLocalization: 'Lokalizacje nośników',
    chosenMedia: 'Wybrane nośniki',
    mediaType: 'Rodzaj nośnika',
    medium: 'Nośnik',
    mediumLocation: 'Położenie nośnika',
    name: 'Nazwa',
    newCampaign: 'Nowa kampania',
    no: 'Nie',
    note: 'Notatka',
    contractNote: 'Notatka kontraktowa',
    noDataFound: 'Nie znaleziono danych',
    noData: 'Brak danych',
    numberOfEmissions: 'Liczba emisji',
    numberOfAudience: 'Liczba kontaktów',
    audienceGender: 'Widownia - płeć',
    audienceAge: 'Widownia - wiek',
    audienceGenderAndAge: 'Widownia - płeć i wiek',
    numberOfAudienceEstimated: 'Estymowana liczba kontaktów',
    distributionOfContacts: 'Rozkład dzienny liczby kontaktów',
    showHiddenTarget: 'Pokaż ukryty cel',
    removeHiddenTarget: 'Usuń ukryty cel',
    hiddenTarget: 'Ukryty cel',
    offerType: 'Typ oferty',
    ok: 'Ok',
    open: 'Otwórz',
    or: 'lub',
    outdoor: 'Outdoor',
    password: 'Hasło',
    pcs: 'szt',
    placementOfMedia: 'Rozmieszczenie nośników',
    pleaseWait: 'Proszę czekać...',
    pois: 'POI',
    priorities: 'Priorytety',
    prioritiesPlaceholder: 'Wybierz priorytety',
    priority: 'Priorytet',
    publishChanges: 'Opublikuj zmiany',
    reason: 'Powód',
    remaining: 'Pozostało',
    remove: 'Usuń',
    repeatPassword: 'Powtórz hasło',
    roles: 'Role',
    rolesPlaceholder: 'Wybierz role',
    save: 'Zapisz',
    search: 'Szukaj',
    select: 'Wybierz',
    selectDots: 'Wybierz...',
    send: 'Wyślij',
    sendToAcceptance: 'Wyślij do akceptacji',
    share: 'Udostępnij',
    show: 'Pokaż',
    status: 'Status',
    standard: 'Standard',
    stop: 'Zatrzymaj',
    supervisors: 'Opiekunowie',
    target: 'Cel',
    targetRealization: 'Realizacja celu',
    thumbnail: 'Miniaturka',
    to: 'Do',
    units: {
      pixels: 'piks.',
      seconds: 'sek.',
    },
    everUsedMedia: 'Wszystkie użyte nośniki',
    usedMedia: 'Używane nośniki',
    yes: 'Tak',
    views: 'wyświetleń',
    campaignId: 'ID kampanii',
    resetPassword: 'Reset hasła',
    copyButton: 'Kopiuj link',
    estimatedValue: 'Szacowana wartość',
    currentValue: 'Aktualny koszt',
    sex: 'Płeć',
    ageGroup: 'Grupa wiekowa',
    gradation: 'Gradacja',
    unit: 'Jednostka',
  },

  sign: {
    privacyPolicy: 'Polityka prywatności',
    signIn: {
      doohLogin: 'Logowanie DOOH',
      checkYourEmail: 'Sprawdź swój e-mail',
      checkYourPassword: 'Sprawdź swoje hasło',
      signIn: 'Zaloguj się',
      forgotPassword: 'Nie pamiętam hasła',
    },
    remindPassword: {
      doNotRemember: 'Nie pamietasz hasła?',
      enterEmail: 'Wpisz adres e-mail powiązany z kontem',
      checkInboxDetails:
        'Właśnie wysłaliśmy Ci instrukcję zmiany hasła. Sprawdź swoją skrzynkę e-mail.',
      backToSignIn: 'Wróć do logowania',
    },
    resetPassword: {
      passwordReset: 'Resetowanie hasła',
      enterNew: 'Wprowadź nowe hasło',
      resetPassword: 'Resetuj hasło',
    },
    acceptInvitation: {
      createNew: 'Załóż nowe konto DOOH',
      createAccount: 'Załóż konto',
      haveNotReceived: 'Nie otrzymałeś wiadomości e-mail?',
    },
  },

  targetFilters: {
    audience: 'Widownia',
    impressions: 'Wyświetlenia',
  },

  sexFilters: {
    '0': 'Mężczyźni',
    '1': 'Kobiety',
  },

  ageGroupFilters: {
    '0': '0-15',
    '1': '15-30',
    '2': '30-45',
    '3': '45-60',
    '4': '60+',
  },

  status: {
    saving: 'zapisuje',
    saved: 'zapisano',
    error: 'błąd',
  },

  topBar: {
    selectProfilePhoto: 'Wybierz zdjęcie profilowe',
    signOut: 'Wyloguj',
    preferredLanguage: 'Preferowany język',
  },

  notification: {
    generalError: 'Wystąpił błąd',
    networkError: 'Błąd sieci',
    logInSuccess: 'Zalogowano pomyślnie',
    logOutSuccess: 'Wylogowano pomyślnie',
    accessDenided: 'Brak dostępu',
    acceptancesUpdate: 'Zaktualizowano akceptacje',
    rejectedCreationsInCampaign: 'Odrzucone kreacje w kampanii',
    emailSent: 'E-mail został wysłany',
    passwordSaveSuccess: 'Pomyślnie zmieniono hasło',
    addAgencySuccess: 'Pomyślnie dodano agencję',
    addRoleSuccess: 'Pomyślnie dodano rolę',
    updateRoleSuccess: 'Pomyślnie zaktualizowano rolę',
    updateAgencySuccess: 'Pomyślnie zaktualizowano agencję',
    updateProfileSuccess: 'Pomyślnie zaktualizowano konto użytkownika',
    updateUserSuccess: 'Pomyślnie zaktualizowano użytkownika',
    inviteUserSuccess: 'Pomyślnie zaproszono użytkownika',
    acceptInvitationSuccess: 'Pomyślnie utworzono konto',
    publishChangesSuccess: 'Pomyślnie opublikowano zmiany w kampanii',
    tooManyCreations: `Maksymalna liczba kreacji do wysłania na raz to ${CREATIONS_MAX_FILES}. Maksymalny limit pliku to ${VariableUtils.formatBytes(
      CREATIONS_MAX_FILE_SIZE
    )} GB. Kreacje zostały odrzucone.`,
    reserveCampaignSuccess: 'Pomyślnie zarezerwowano kampanię',
    updateCampaignReservationSuccess: 'Pomyślnie zaktualizowano rezerwację kampanii',
    confirmCampaignReservationSuccess: 'Pomyślnie potwierdzono rezerwację kampanii',
    cancelCampaignReservationSuccess: 'Pomyślnie anulowano rezerwację kampanii',
    sendCampaignToAcceptanceSuccess: 'Pomyślnie wysłano kampanie do akceptacji',
    exceeded70PercentMediumsAvailabilityWarning:
      'Zajętość nośników w miastach {{ cities }} w tygodniu {{ week }} wynosi więcej niż 70%',
  },

  campaigns: {
    time: 'Czas',
  },

  campaignStatus: {
    sketch: 'Szkic',
    planned: 'Zaplanowana',
    active: 'Aktywna',
    finished: 'Zakończona',
    paused: 'Wstrzymana',
    stopped: 'Zatrzymana',
    reserved: 'Rezerwacja',
    pendingAcceptation: 'W trakcie akceptacji',
    reservationCancelled: 'Rezerwacja anulowana',
  },

  campaign: {
    realisation: 'Realizacja',
    details: 'Szczegóły',
    creations: 'Kreacje',
    creation: 'Kreacja',
  },

  campaignDetails: {
    emissionTime: 'Czas emisji',
    locationsReport: 'Wygeneruj raport z lokalizacjami kampanii',
    acceptancesReport: 'Wygeneruj raport z akceptacjami',
    info: {
      acceptanceFields: 'Pola akceptacji',
      accepted: 'Akceptacja ',
      notAccepted: 'Brak akceptacji',
      noCreations: 'Brak kreacji',
      noApprovals: 'Brak zatwierdzeń',
      notAbleToEditWhenAccepting: 'Edycja jest niemożliwa podczas akceptacji',
    },
    chart: {
      noData: 'Brak danych do wygenerowania wykresu',
      sumOfEmissions: 'Suma emisji',
      sumOfAudience: 'Suma kontaktów',
      sumOfAudienceEstimated: 'Estymowana suma kontaktów',
      budgetForecast: 'Prognoza budżetu',
    },
    realization: {
      header: 'Realizacja',
      days: 'dni',
    },
    shareUrl: {
      title: 'Udostępnianie szczegółów kampanii',
      info: 'Udostępnij szczegóły kampanii osobom, które nie mają dostępu do aplikacji.\nSkopiuj link i prześlij go do zainteresowanych osób. Dzięki temu inni będą mogli zobaczyć te same dane, ale bez możliwości edycji.',
      downloadLink: 'Pobierz link',
      copyLink: 'Kopiuj link',
    },
    creations: 'Kreacje',
    noCreationsInfo: 'Aby dodać kreacje - przejdź do edycji kampanii',
    pause: 'Pauza',
    finish: 'Zakończ',
  },

  campaignHistory: {
    alter:
      'Atrybut <attribute>{{attribute}}</attribute> został zmieniony z <before>{{before}}</before> na <after>{{after}}</after>',
    by: 'przez',
    title: 'Historia kampanii',
    noData: 'Nie ma jeszcze żadnych wpisów',
    categories: {
      campaign_created: 'Kampania została stworzona',
      campaign_updated: 'Kampania została zmieniona',
      creation_archived: 'Kreacja została zarchiwizowana',
      creation_created: 'Kreacja <subject>{{subject}}</subject> została stworzona',
      creation_deleted: 'Kreacja <subject>{{subject}}</subject> została usunięta',
      creation_updated: 'Kreacja <subject>{{subject}}</subject> została zmieniona',
      sent_to_brain: 'Kampania została wysłana do braina',
      sent_to_acceptance: 'Kampania została wysłana do akceptacji',
    },
  },

  form: {
    all: 'Wszystkie',
    selectAll: 'Zaznacz wszystkie',
    deselectAll: 'Odznacz wszystkie',
    selected: 'Wybrano',
    noOptions: 'Brak opcji',
    invalidDate: 'Niepoprawna data',
    startDateAfter: 'Data startu jest przed zakończeniem',
    endDateBefore: 'Data zakończenia jest przed startem',
    campaign: {
      edit: 'Edycja kampanii',
      basics: {
        briefNamePlaceholder: 'Wpisz nazwę briefu',
        advertiserHelp: 'Wprowadź minimum 4 znaki',
        agencyHelp:
          'Na liście znajdziesz agencje, które zostały dodane do systemu. Aby dodać nową agencję przejdź do zakładki "agencje" w lewym menu.',
        agencyNamePlaceholder: 'Wybierz agencję z listy',
        supervisorsPlaceholder: 'Wybierz opiekunów z listy',
        endDate: 'Data zakończenia',
        header: 'Informacje podstawowe',
        name: 'Nazwa kampanii',
        namePlaceholder: 'Wpisz nazwę kampanii',
        startDate: 'Data rozpoczęcia',
      },
      details: {
        poisHelp:
          'Aby sprecyzować wyszukiwanie dodaj POI. Wpisz pierwszą literę kategorii i wybierz pozycję z listy. Możesz dodać więcej niż jedną kategorię. Na mapie po prawej stronie zobaczysz dostępność nośników.',
        target: 'Cel kampanii',
        maxBudget: 'Maksymalny budżet',
        budgetHelp:
          'Maksymalny budżet to górna granica ceny. Po wybraniu parametrów kampanii w prawym górnym rogu znajdziesz szacowaną cenę. Jeśli szacowana cena jest niższa od maksymalnego budżetu - zmień parametry kampanii (np. ustawienia lokalizacji, POI lub rodzaje nośników).',
        priorityHelp:
          'Priorytet to informacja z jakim priorytetem kampania zostanie dopasowana do dostępnych nośników. Może przybierać wartości od {{ priorityMin }} do {{ priorityMax }}, gdzie {{ priorityMax }} to najwyższy priorytet.',
        emissionsHelp:
          'Liczba emisji nie powinna być wyższa niż szacowany maksymalny potencjał. Ustalenie wyższej wartości może spowodować, że kampania nie zrealizuje się w 100%.',
        actualPlannedTarget: 'Rzeczywisty planowany cel.',
        offerPlaceholder: 'Wybierz typ oferty',
        emissionType: 'Typ emisji',
        synchronizedEmission: 'Emisja synchroniczna',
        synchronizedEmissionHelp:
          'Emisja synchroniczna: umożliwia wyświetlanie tego samego materiału na wielu nośnikach jednocześnie',
      },
      summary: {
        estimatedEmissions: 'Szacowany maksymalny potencjał',
        estimatedEmissionsV2: 'Szacowany maksymalny potencjał v2',
        recalculate: 'Przelicz',
        audience: 'Szacowana ilość emisji',
      },
      creations: {
        invalidCreationEmissions: 'Nieprawidłowa liczba emisji',
        creationEmissions: 'Liczba wyświetleń kreacji',
        creationAudience: 'Liczba kontaktów kreacji',
        emissionRealization: 'Realizacja emisji',
        creationsEmissionsNumberCantBeLowerThanCampaigns:
          'Suma liczby emisji kreacji jest niższa niż liczba emisji kampanii.',
        creationsEmissionsNumberCantBeHigherThanCampaigns:
          'Suma liczby wyświetleń wszystkich kreacji nie może być wyższa niż zaplanowana liczba wyświetleń kampanii.',
        creationsEmissionsNumberShouldBeEqualToCampaigns:
          'Liczba emisji nie powinna być większa, ani niższa niż łączny cel kampanii.',
        creationsAudienceNumberCantBeLowerThanCampaigns:
          'Suma liczby widowni kreacji jest niższa niż liczba widowni kampanii.',
        creationsAudienceNumberCantBeHigherThanCampaigns:
          'Suma liczby widowni wszystkich kreacji nie może być wyższa niż zaplanowana liczba widowni kampanii.',
        creationsAudienceNumberShouldBeEqualToCampaigns:
          'Liczba widowni nie powinna być większa, ani niższa niż łączny cel kampanii.',
        zipInfo: 'Aby dodać kreacje ZIP skontaktuj się ze swoim opiekunem AMS',
        addCreation: 'Dodaj kreacje',
        alertTop: 'Wystąpił problem techniczny z kreacją',
        alertSolution: 'W celu rozwiązania problemu skontaktuj się ze swoim opiekunem AMS.',
        header: 'Kreacje',
        addingCreations: 'Dodawanie kreacji',
        noCreation: 'Brak kreacji',
        dropZoneOptions: {
          minWidth: 'Min. szerokość',
          minHeight: 'Min. wysokość',
          maxFileSize: 'Maks. rozmiar pliku',
          maxMovieLength: 'Maks. długość filmu',
          preferred: 'Preferowane',
        },
        sendToAcceptance: 'Wyślij do akceptacji',
        acceptancesList: 'Lista akceptacji',
        agglomeration: 'Aglomeracja',
        acceptances: 'Akceptacje',
        accepting: 'W trakcie akceptacji',
        rejected: 'Odrzucono',
        accepted: 'Zaakceptowano',
        acceptedFormats: 'Akceptowane formaty',
        maxFiles: 'Maksymalna liczba plików',
        maxFileDimensions: 'Maks. rozdzielczość pliku',
        maxFileSize: 'Maksymalny rozmiar pliku',
        dropHere: 'Przeciągnij plik tutaj',
        toSelectFile: 'aby wybrać plik',
        editEmission: 'Edycja emisji',
        duration: 'Długość kreacji',
        asIdNameHasBeenSaved: 'Nazwa AS_ID {{name}} została zapisana',
        schedules: {
          selectedDates: 'Wybrane daty',
        },
        archive: {
          success: 'Pomyślnie zarchiwizowano kreację',
        },
        archiveButtonInfo:
          'Archiwizuj, powoduje przeniesienie kreacji do archiwum. Możliwy będzie jej podgląd, natomiast ponowne użycie wymaga dodania jej jeszcze raz.',
        containsAlcohol: 'Motyw alkoholowy',
      },
      approval: {
        success: 'Pomyślnie aktywowano kampanię',
        saveAsDraft: 'Zapisz jako szkic',
      },
      removal: {
        success: 'Kampania została pomyślnie usunięta',
      },
      finish: {
        success: 'Kampania została pomyślnie zakończona',
      },
      save: {
        success: 'Kampania została pomyślnie zapisana',
      },
      reject: {
        success: 'Odrzucono kampanie',
      },
      copy: {
        success: 'Pomyślnie skopiowano kampanię',
      },
    },
  },

  media: {
    city: 'Miasto',
    address: 'Adres',
    screenCount: 'Liczba ekranów',
    screenCountShort: 'Ekr.',
  },

  map: {
    holdButton: 'Przytrzymaj przycisk',
    toZoomIn: 'aby przybliżyć',
  },

  mediaType: {
    dgCl: 'Digital Citylight (DG_CL)',
    dgIn: 'Digital Indoor (DG_IN)',
    dgInH: 'Digital Indoor Horizontal (DG_IN_H)',
    dgInS: 'Digital Indoor Spectacular (DG_IN_S)',
    dgCs: 'Digital Cityscreen (DG_CS)',
  },

  pageNotFound: {
    notExists: 'Wygląda na to, że strona o podanym adresie nie istnieje',
    goToCampaigns: 'Przejdź do kampanii',
  },

  agencies: {
    agencies: 'Agencje',
    endAgencies: 'Agencje',
    remove: {
      success: 'Agencja została pomyślnie usunięta',
    },
    form: {
      newAgency: 'Nowa agencja',
      editAgency: 'Edycja agencji',
      agencyName: 'Nazwa agencji',
      clientId: 'Client ID',
      enterAgencyName: 'Wpisz nazwę agencji',
      enterClientId: 'Wpisz Client ID',
      internal: 'Wewnętrzna',
      nip: 'NIP',
      street: 'Ulica',
      apartmentNumber: 'Nr mieszkania',
      buildingNumber: 'Nr budynku',
      postalCode: 'Kod pocztowy',
      city: 'Miasto',
      crmId: 'Crm Id',
      currency: 'Waluta',
      agencyOrigin: 'Lokalizacja agencji',
      domestic: 'Krajowa',
      maximumPotential: 'Maksymalny potencjał',
      foreign: 'Zagraniczna',
      discount: 'Zniżka',
      billingAllowance: 'Zezwól na bilingi',
      mediumsOwner: 'Właściciel nośników',
      allocatedTime: 'Przydzielony czas',
      mediumsAllocatedTime: 'Czas przydzielony nośnikom',
    },
  },

  priority: {
    low: 'Niski',
    high: 'Wysoki',
  },

  user: {
    settings: {
      header: 'Ustawienia konta',
      profile: 'Profil',
      changePassword: 'Zmiana hasła',
      previousPassword: 'Poprzednie hasło',
      newPassword: 'Nowe hasło',
      repeatNewPassword: 'Powtórz nowe hasło',
      enterPreviousPassword: 'Wpisz poprzednie hasło',
      enterNewPassword: 'Wpisz nowe hasło',
      reEnterNewPassword: 'Wpisz ponownie nowe hasło',
    },
    all: {
      users: 'Użytkownicy',
      list: 'Lista użytkowników',
      role: 'Rola',
    },
    roles: 'Role',
    status: {
      active: 'Aktywny',
      deactivated: 'Dezaktywowany',
      invited: 'Zaproszony',
      subscription: 'Subskrypcja',
    },
    edit: {
      header: 'Edycja użytkownika',
    },
    invite: {
      header: 'Nowy użytkownik',
      sendInvitation: 'Wyślij zaproszenie',
    },
    confirm: {
      header: 'Potwierdzenie',
      description:
        'Zmiana dostępnych priorytetów może wpłynąć na ustawienia kampanii tego użytkownika. Czy chcesz zapisać zmiany?',
    },
    resetPassword: {
      header: 'Hasło użytkownika zostało zresetowane',
      description: 'Link do aktywacji nowego hasła',
      placeholder: 'Link do aktywacji nowego hasła',
    },
    reInviteModal: {
      header: 'Ponowne zaproszenie zostało wysłane',
      description: 'Link do aktywacji konta',
      placeholder: 'URL',
      action: 'Wyślij ponownie zaproszenie',
    },
    changeEmailModal: {
      action: 'Zmień adres email',
      header: 'Zmiana e-maila użytkownika',
      topLabel: 'Aktualny e-mail',
      bottomLabel: 'Wprowadź nowy e-mail',
      placeholder: 'xyz@gmail.com',
      error: 'Wprowadź poprawny adres e-mail',
    },
  },

  emissonSchedule: {
    titleEdit: 'Edycja planu emisji',
    duration: 'Czas trwania',
    info: 'W tym miejscu możesz ustawić plan emisji danej kreacji. Jeśli nie wybierzesz parametrów w szczegółach emisji, kampania będzie się wyświetlić na zasadach ogólnych ustawionych w szczegółach kampanii.',
    infoA:
      'Wybierz czas trwania emisji w kalendarzu. Możesz zaznaczać pojedyncze dni i przedziały czasowe.',
    infoB: '- aby zaznaczyć <0>{{ single }}</0> dzień emisji kliknij dwa razy na wybraną datę',
    infoC:
      '- aby zaznaczyć <0>{{ timePeriod }}</0> kliknij dzień rozpoczęcia emisji i dzień zakończenia emisji',
    infoD: '- aby usunąć zaznaczony dzień lub zakres kliknij go ponownie',
    timePeriod: 'przedział czasowy',
    single: 'pojedynczy',
    noMediaSelected: 'Nie wybrano żadnej lokalizacji.',
  },

  modal: {
    doYouWant: 'Czy chcesz',
    doYouWantToRemove: 'Czy chcesz usunąć',
    doYouWantToStopCampaign: 'Czy chcesz zatrzymać kampanię',
    agencyRemoval: 'Usuwanie agencji',
    creationRemoval: 'Usuwanie kreacji',
    campaignRemoval: 'Usuwanie kampanii',
    campaignEditing: 'Edytowanie kampanii',
    editCampaignInfo:
      'Edytujesz aktywną kampanię. Po zmianie danych kampania na nowych parametrach zostanie uruchomiona po godzinie. Do tego czasu będą wyświetlane dane na bazie poprzednich ustawień.',
    editCampaignItem: 'edytować aktywną kampanię',
    pauseCampaign: 'Pauza kampanii',
    doYouWantToPauseCampaign: 'Czy chcesz zatrzymać tymczasowo kampanię',
    pause: 'Pauzuj',
    stoppingCampaign: 'Zatrzymanie kampanii',
    stopCampaignDescription: 'Zatrzymanej kampanii nie można wznowić.',
    creationArchiving: 'Archiwizowanie kreacji',
    archivedCreation: 'Zarchiwizowana kreacja',
    doYouWantToArchive: 'Czy chcesz zarchiwizować kreacje',
    archive: 'Archiwizuj',
    doYouWantToDeactivateUser: 'Czy chcesz dezaktywować użytkownika',
    deactivationUser: 'Dezaktywacja użytkownika',
    removeRole: 'Usuwanie roli',
    doYouWantToRemoveRole: 'Czy chcesz usunąć rolę',
    deactivate: 'Dezaktywuj',
    approveCampaign: 'Aktywowanie kampanii',
    rejectCampaign: 'Odrzucenie kampanii',
    createCampaignCopy: 'Utwórz kopie kampanii',
    doYouWantToApproveCampaign: 'Czy chcesz aktywować kampanię',
    doYouWantToRejectCampaign: 'Czy odrzucić kampanię',
    doYouWantToCreateCampaignCopy: 'Czy chcesz utworzyć kopie kampanii',
    activate: 'Aktywuj',
    reject: 'Odrzuć',
    createCopy: 'Utwórz kopie',
    publishingChanges: 'Publikowanie zmian',
    doYouWantToPublishCampaign: 'Czy chcesz opublikować zmiany w kampanii',
    publish: 'Opublikuj',
    reservationConfirmation: 'Potwierdzenie rezerwacji',
    doYouWantToConfirmReservation: 'Czy potwierdzasz rezerwację',
    reservationCancellation: 'Anulowanie rezerwacji',
    doYouWantToCancelReservation: 'Czy chcesz anulować rezerwację',
  },

  report: {
    report: 'Raport',
    generatingReport: 'Generowanie raportu',
    noData: 'Brak danych',
    campaignReport: 'Raport kampanii',
    creationReport: 'Raport kreacji',
    mediumCampaignsReport: 'Raport emitowanych kampanii na nośniku',
    mediumReport: 'Raport nośnika',
    mediaReport: 'Raport nośników',
    campaignId: 'ID kampanii',
    mediumId: 'ID medium',
    mediaIds: 'ID mediów',
    mediaIdsHelp: 'Numery ID mediów należy wpisać po przecinku lub spacji',
    generate: 'Generuj',
    longTimeInfo: 'Generowanie raportu może chwilę potrwać.',
    dataReadInfo:
      'Wyniki w postaci 150/100/50 czytamy jako alokacje/sloty/logi. Jeżeli jest tylko jedna wartość to znaczy ze wszystkie sę równe.',
    dataReadInfoA:
      '- alokacje - wartość alokacji zaplanowanych w harmonogramie, która jest rozplanowywana na sloty',
    dataReadInfoB: '- sloty - wartość zaplanowanych slotów na nośniku',
    dataReadInfoC: '- logi - wartość otrzymana z logów Media Managera',
    massCampaignReport: 'Połączone raporty kampanii PDF',
    firstColumn: 'Nazwa',
    secondColumn: 'Zakres raportu',
    thirdColumn: 'Status',
    fourthColumn: 'Data wygenerowania',
    fifthColumn: 'Link do pobrania',
    pendingStatus: 'Oczekuje',
    finishedStatus: 'Gotowy',
    errorStatus: 'Wystąpił błąd',
    priceReportInfo: 'Ceny znajdujące się w tabeli podane są w cenie netto',
    priceReportHeader: 'Cennik',
    audience: 'Publiczność',
    impressions: 'Impresje',
    audienceNoData: 'Brak danych o publiczności',
    impressionsNoData: 'Brak danych o impresjach',
  },

  role: {
    admin: 'Admin',
    client: 'Klient',
    employee: 'Pracownik',
    planner: 'Planer',
    account: 'Handlowiec',
    support: 'Wsparcie',
  },

  errors: {
    required: 'To pole jest wymagane.',
    maxSafeInteger: 'Przekroczono dopuszczalną wartość 2 147 483 647.',
    invalidEncodingFormat: 'Niepoprawny format kodowania.',
    invalidNumericFormat: 'Niepoprawny format. Wymagana liczba.',
    invalidNumberSeparation: 'Niepoprawnie rozdzielone liczby.',
    invalidEmail: 'Niepoprawny adres e-mail',
    differFromEachOther: 'Pole różni się od poprzedniego.',
    maxNumberOfCharactersExceeded: 'Przekroczono maksymalną ilość znaków',
  },

  inventory: {
    title: 'Inventory',
    systemCapacity: 'Pojemność systemu',
    lastUpdate: 'Ostatnia aktualizacja',
    allocatedSecondsQuantity: 'Ilość zajętych sekund',
    allocatedSlotsQuantity: 'Ilość zajętych slotów',
    secondsQuantity: 'Ilość wszystkich sekund',
    slotsQuantity: 'Ilość wszystkich slotów',
    plannedSecondsQuantity: 'Ilość zaplanowanych sekund',
    plannedSlotsQuantity: 'Ilość zaplanowanych slotów',
    reservedSecondsQuantity: 'Ilość zarezerwowanych sekund',
    reservedSlotsQuantity: 'Ilość zarezerwowanych slotów',
    freeSecondsQuantity: 'Ilość wolnych sekund',
    freeSlotsQuantity: 'Ilość wolnych slotów',
    allocatedSecondsLabel: 'Zajęte sekundy',
    seconds: 'Sekundy',
    allocatedSeconds: 'Zajęte sekundy',
    reservedSeconds: 'Zarezerwowane sekundy',
    plannedSeconds: 'Zaplanowane sekundy',
    freeSeconds: 'Wolne sekundy',
    priorityThree: 'Zajęte sloty /priorytet "3"',
    priorityFour: 'Zajęte sloty /priorytet "4"',
    priorityFive: 'Zajęte sloty /priorytet "5"',
    prioritySix: 'Zajęte sloty /priorytet "6"',
    prioritySeven: 'Zajęte sloty /priorytet "7"',
    freeSlots: 'Wolne sloty',
  },

  filters: {
    filter: 'Filtruj',
    clearFilters: 'Wyczyść filtry',
    onlyAlerts: 'Pokaż tylko z alertami',
    start: 'Start',
    end: 'Koniec',
    periodGranulation: {
      daily: 'Dzienna',
      weekly: 'Tygodniowa',
      monthly: 'Miesięczna',
    },
  },

  mediumStatus: {
    active: 'Aktywny',
    inactive: 'Nieaktywny',
    not_operating: 'Nie działa',
    removed: 'Usunięty',
  },

  pdf: {
    archiveReason: 'Powód archiwizacji',
    page: 'strona',
    campaignSummary: 'Podsumowanie kampanii',
    campaignStatus: 'Status kampanii',
    number: 'L.p.',
    campaignName: 'Nazwa kampanii',
    plannedContacts: 'Zaplanowane kontakty',
    completedContacts: 'Zrealizowane kontakty',
    notCompleteData:
      '* Jesteś szybsza/y niż nasze zwinne algorytmy :) Dane demograficzne dot. kampanii będą dostępne 3 dni po jej zakończeniu.',
    male: 'Mężczyźni',
    female: 'Kobiety',
  },

  reservation: {
    reserve: 'Rezerwuj',
    edit: 'Edycja rezerwacji',
    title: 'Wybór daty rezerwacji',
    info: 'Rezerwacja obowiązuje do godziny 18.00 dnia wybranego w kalendarzu',
    disabledInfo: 'Brak możliwości utworzenia rezerwacji na kampanię startującą dzisiaj',
    confirmReservation: 'Potwierdź rezerwację',
    cancelReservation: 'Anuluj rezerwację',
  },

  mediaSelection: {
    title: 'Wybór nośników',
    searchAddress: 'Szukaj adresu',
    mediaToImport: 'Import nośników',
    mediaToImportPlaceholder: `Wpisz nośniki do importu. Dozwolone separatory: spacja, przecinek, średnik.`,
    template: {
      name: 'Nazwa szablonu',
      save: 'Zapisz jako szablon',
      title: 'Szablony',
      removeMessage: 'Usunąć szablon <template>{{template}}</template>?',
      create: {
        success: 'Pomyślnie utworzono szablon',
      },
      remove: {
        success: 'Pomyślnie usunięto szablon',
      },
    },
  },

  attributesSelection: {
    title: 'Atrybuty handlowe/organizacyjne',
    organizationAttributes: 'Atrybuty organizacyjne',
    commercialAttribute: 'Atrybut handlowy',
    commercialAttributes: 'Atrybuty handlowe',
    social: 'Społeczna (w tym autopromocja)',
    commercial: 'Kontent komercyjny',
    outsidePh: 'Poza PH',
    complaint: 'Reklamacyjna',
    freeComplaint: 'Reklamacyjna bezpłatna',
    complaintDetail: 'Wpisz id kampanii',
    productOffer: 'Oferta produktowa',
    commercialAttributesValues: {
      best_effort: 'Best effort',
      automatic_guarantee: 'Direct Guarantee',
    },
  },

  mediumGroup: {
    AGGLOMERATION: 'aglomeracja',
    CITY: 'miasto',
    BUILDING: 'galeria',
  },

  offer: {
    run_on_network: 'Cała sieć (RON)',
    agglomerations: 'Aglomeracje',
    buildings: 'Galerie',
    media_selection: 'Selekcja nośników',
  },

  roles: {
    form: {
      newRole: 'Nowa rola',
      editRole: 'Edycja roli',
      baseRole: 'Rola bazowa',
    },
    privileges: {
      title: 'Uprawnienia',
    },
  },
}
